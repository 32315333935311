import { getCurrentInstance } from 'vue'
import type { SwiperOptions } from 'swiper/types/swiper-options'

/**
 * This method fix swiper styles on SSR (sets proper grid for multiple elements)
 * @param settings  - swiper settings
 * @returns css for ssr styles
 */
function useSsrSwiperStyles(settings: SwiperOptions) {
  const $vm = getCurrentInstance()
  const CSS_SCOPED_DATA_ATTR: string = $vm?.type?.__scopeId || ''

  if (!CSS_SCOPED_DATA_ATTR) {
    console.warn('Remember about scoped styles')
  }

  const breakpoints = settings.breakpoints
  const cssMediaQueries: string[] = []
  const SWIPER_WRAPPER = `.horizontal-slider[${CSS_SCOPED_DATA_ATTR}]:not(.swiper-initialized) .swiper-wrapper`

  // add grid styles
  cssMediaQueries.push(
    `${SWIPER_WRAPPER} {display: grid; gap: ${settings.spaceBetween ?? 0}px;}}`,
  )

  // add template columns
  if (breakpoints) {
    // for mobile
    const mobileGrid = '1fr '.repeat(settings.slidesPerView).trim()
    cssMediaQueries.push(
      `@media (max-width: ${Object.keys(breakpoints)[0]}px) {
        ${SWIPER_WRAPPER} {grid-template-columns: ${mobileGrid};}
        ${SWIPER_WRAPPER} .swiper-slide:nth-child(${
          settings.slidesPerView + 1
        }) ~ .swiper-slide {display: none;}
      }`,
    )

    // for >mobile
    Object.keys(breakpoints).forEach((breakpoint) => {
      const slides = Number(breakpoints[breakpoint].slidesPerView)
      const slidesGrid = '1fr '.repeat(slides)
      cssMediaQueries.push(
        `@media (min-width: ${breakpoint}px) {
          ${SWIPER_WRAPPER} {grid-template-columns: ${slidesGrid.trim()};}
          ${SWIPER_WRAPPER} .swiper-slide:nth-child(${
            slides + 1
          }) ~ .swiper-slide {display: none;}
        }`,
      )
    })
  }

  return cssMediaQueries
}

export default useSsrSwiperStyles
